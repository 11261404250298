// import Menu from './components/Menu'
// import PageHome from './pages/Home'
// import PageSpaces from './pages/Spaces'
// import PageLocation from './pages/Location'
import 'alpinejs';
import CC from 'cookieconsent';
//import './utils/filtros';
// import SmoothScroll from 'smooth-scroll'
// import Vue from 'vue'
// import '../../node_modules/swiper/dist/css/swiper.min.css'
// import '../../node_modules/cookieconsent/build/cookieconsent.min.css'


/**
 * Init Global elements
 */
// window.menu = new Menu()
// window.scroll = new SmoothScroll('a[href*="#"]', {speed: 500, speedAsDuration: true, topOnEmptyHash: true, updateURL: false})
window.cookieconsent.initialise({
  palette: {popup: {background: '#bc492d'}, button: {background: 'transparent', text: '#fff', border: '#fff'}},
  content: {message: window.locales.cookieMessage, dismiss: window.locales.cookieDismiss, link: window.locales.cookieLink, href: window.locales.cookieHref}
})
